import api from "./core/api";

const BASE_PATH = '/shopify';

async function authenticate(message, shop, hmac) {
  return await api.post(BASE_PATH + '/authenticate', {
    message,
    shop,
    hmac
  });
}

async function authorize(message, shop, hmac, nonce, code) {
  return await api.post(BASE_PATH + '/authorize', {
    message,
    shop,
    hmac,
    nonce,
    code
  });
}

async function login(shop) {
  return await api.post(BASE_PATH + '/login', {
    shop
  });
}

const shopify = {
  authenticate: authenticate,
  authorize: authorize,
  login: login
};

export default shopify;
