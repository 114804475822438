import React, {useEffect} from "react";
import shopify from "../../backend/shopify";
import Loader from "../../components/Core/Loader";

const AuthorizePage = () => {
  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const hmac = urlParams.get("hmac");
    const shop = urlParams.get("shop");
    const state = urlParams.get("state");
    const code = urlParams.get("code");

    shopify.authorize(urlParams.toString(), shop, hmac, state, code)
      .then(({data}) => {
        const {url, jwt} = data;

        if (typeof url === "undefined") {
          alert("Error! Please try again.");

        } else {
          // @todo store in cookie instead
          localStorage.setItem('token', jwt);

          window.location.href = url;
        }
      });
  }, []);

  return (
    <Loader/>
  );
};

export default AuthorizePage;
