import React from "react"
import styled from "styled-components";
import colors from "../../styles/colors";
import breakpoints from "../../styles/breakpoints";
import loaderImage from "../../resources/img/loader/loader.gif";

const LoaderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${colors.grey};
  
  @media(min-width: ${breakpoints.md}) {
    height: 100vh;
  }
`;

const LoaderImage = styled.img`
  width: 60px;
  position: relative:
  margin-top: calc(-60px -24px);
 
  @media(min-width: ${breakpoints.md}) {
    width: 120px;
    margin-top: calc(-120px -48px);
  }
`;

const Loader = () => {
  return (
    <LoaderContainer>
      <LoaderImage src={loaderImage} alt={"Loading..."} />
    </LoaderContainer>
  );
};

export default Loader;